import styled from "styled-components"

import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import TextField, { TextFieldProps } from "@material-ui/core/TextField"
import ClearIcon from "@material-ui/icons/Clear"

import { backgroundGrayV2, primaryTint } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

import SearchSvg from "./noun_Search_860389.svg"

type StyledTextFieldProps = TextFieldProps & {
  basewidth: string
  expandedwidth: string
}
type TStyledTextField = React.ComponentType<
  TextFieldProps & StyledTextFieldProps
>

const StyledTextField = styled(TextField as TStyledTextField)`
  .MuiOutlinedInput-root {
    transition: width 150ms ease-in-out;
    background: ${backgroundGrayV2} 0% 0% no-repeat padding-box;

    /* Only shrink/grow in desktop mode */
    @media (min-width: 800px) {
      width: ${(props) => props.basewidth};
      &.Mui-focused {
        width: ${(props) => props.expandedwidth};
      }
    }

    /* Overriding Mui component styles according to:
    https://material-ui.com/styles/advanced/#with-material-ui-core */
    fieldset {
      border: 1px solid #dedede;
      border-radius: 5px;
    }
    &:hover fieldset {
      border-color: ${primaryTint};
    }
    &.Mui-focused fieldset {
      border-color: ${primaryTint};
    }
  }

  .MuiOutlinedInput-input {
    padding: ${spacing.S};
  }
`

const StyledIconButton = styled(IconButton)`
  padding: 0.25rem;
  &:disabled {
    color: rgba(0, 0, 0, 0.54);
  }
`

interface ISearchField {
  value: string
  placeholder: string
  onChange: (s: string) => void
  onClear?: () => void
  expand?: number
  minWidth?: number
  disabled?: boolean
  fullWidth?: boolean
  size?: "small" | "medium"
}

export function SearchField({
  value,
  placeholder,
  onChange,
  onClear,
  minWidth,
  expand = 10,
  disabled,
  fullWidth,
  size = "medium",
  ...props
}: ISearchField) {
  const clear = onClear ? onClear : () => onChange("")
  const _minWidth = minWidth ? minWidth : (placeholder.length || 15) + 5
  const expandedWidth = _minWidth + expand
  const baseWidth = value ? expandedWidth : _minWidth

  return (
    <StyledTextField
      onChange={(ev) => onChange(ev.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {value?.length ? (
              <StyledIconButton aria-label="clear" onClick={clear}>
                <ClearIcon />
              </StyledIconButton>
            ) : (
              <StyledIconButton aria-label="search" disabled>
                <SearchSvg />
              </StyledIconButton>
            )}
          </InputAdornment>
        ),
      }}
      value={value}
      basewidth={fullWidth ? "100%" : `${baseWidth}ch`}
      expandedwidth={fullWidth ? "100%" : `${expandedWidth}ch`}
      fullWidth={fullWidth}
      placeholder={placeholder}
      size={size}
      disabled={disabled}
      {...props}
    />
  )
}
